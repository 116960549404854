/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 20,
    height= 27,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
      <svg width="${width}" height="${height}" viewBox="0 0 20 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Path</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M10.0501608,26.9990713 C16.0167203,19.9483571 19,14.5376667 19,10.767 C19,4.82 14.9704545,1 10,1 C5.02954545,1 1,4.82 1,10.767 C1,14.4756667 4.01672027,19.8863571 10.0501608,26.9990713 Z" id="Path" stroke="${strokeColor}" fill="${backgroundColor}" fill-rule="nonzero"></path>
          <text fill="white"
              font-family="Arial-BoldMT,Arial"
              font-size="12"
              font-weight="bold">
          <tspan x="50%" y="15" fill="${labelColor}" text-anchor="middle">
          
          

          </tspan>
        </text>
        </g>

<g>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="6px" y="-1px"
	 width="50%" viewBox="0 0 2608 3000" enable-background="new 0 0 2608 3000" xml:space="preserve">
<path fill="#fff" opacity="1.000000" stroke="none" 
	d="
M716.512207,3001.317383 
	C478.040375,3002.000000 240.080780,3002.000000 2.121174,3002.000000 
	C2.080799,3001.707031 2.005000,3001.414307 2.004998,3001.121338 
	C2.000038,2001.624268 2.000000,1002.127258 2.000000,2.000000 
	C476.708282,2.000000 951.416809,2.000000 1427.062744,2.000000 
	C1438.706665,4.319935 1449.548584,2.401063 1461.120605,3.029759 
	C1538.750854,3.091695 1614.722534,11.925748 1690.249146,25.227116 
	C1754.691406,36.576366 1817.965698,52.599930 1879.937500,73.611374 
	C1980.043945,107.552330 2073.871338,153.998993 2158.852539,217.122650 
	C2260.947021,292.958160 2342.785156,386.387909 2401.844482,499.580383 
	C2450.502686,592.838989 2480.078857,691.810364 2494.728760,795.642761 
	C2503.735840,859.481445 2506.709717,923.688354 2504.293945,987.959229 
	C2499.763428,1108.505005 2476.854004,1225.407227 2429.180420,1336.729492 
	C2395.498779,1415.379517 2351.479248,1487.901367 2295.412354,1552.616699 
	C2226.944092,1631.646362 2147.257324,1697.256714 2055.631104,1748.014771 
	C2002.776489,1777.294800 1948.230347,1802.876343 1891.106201,1822.802246 
	C1874.688965,1828.528809 1874.779541,1828.746826 1884.106689,1843.457153 
	C1954.951782,1955.188599 2025.327881,2067.216064 2095.946289,2179.090332 
	C2167.808594,2292.935303 2239.635254,2406.803467 2311.479004,2520.660400 
	C2384.211426,2635.925781 2456.946777,2751.189453 2529.579102,2866.518066 
	C2554.262939,2905.711914 2578.892090,2944.942383 2603.814941,2983.985596 
	C2606.368652,2987.986084 2608.455566,2992.046387 2609.549072,2997.386230 
	C2609.176514,2999.254150 2611.944336,3002.235596 2608.005371,3002.022949 
	C2605.681396,3001.897461 2603.344971,3002.000000 2601.014160,3002.000000 
	C2337.967773,3002.000000 2074.921143,3002.000000 1810.937256,3002.000000 
	C1805.653076,2999.736328 1803.189087,2995.804199 1800.730835,2991.790039 
	C1735.120728,2884.642578 1669.530029,2777.482910 1603.889282,2670.354248 
	C1500.291504,2501.278320 1396.632935,2332.239746 1293.135986,2163.102051 
	C1249.650146,2092.035889 1205.862793,2021.153076 1162.697510,1949.892700 
	C1157.428589,1941.194336 1151.139160,1938.665771 1141.530151,1938.681641 
	C1005.546204,1938.905640 869.561768,1938.829834 733.577454,1938.840210 
	C718.874451,1938.841309 718.839539,1938.880859 718.839233,1954.053223 
	C718.831787,2297.013672 718.840393,2639.974365 718.781555,2982.934814 
	C718.780518,2988.853760 719.951233,2994.972412 716.512207,3001.317383 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M2610.000000,2997.062744 
	C2591.865967,2969.518799 2573.647705,2941.091064 2555.613770,2912.546875 
	C2502.863770,2829.052979 2450.166260,2745.525391 2397.487793,2661.986084 
	C2340.378418,2571.420166 2283.342285,2480.808350 2226.221436,2390.249756 
	C2170.162842,2301.375244 2114.012939,2212.558350 2057.949951,2123.687012 
	C2004.726929,2039.317505 1951.567139,1954.908203 1898.371582,1870.521362 
	C1891.102417,1858.989746 1883.758545,1847.505127 1876.517090,1835.956299 
	C1874.786987,1833.197144 1872.695068,1830.565918 1871.906128,1827.003906 
	C1905.743164,1815.140015 1938.887695,1802.494995 1971.248413,1787.834106 
	C2032.307007,1760.171265 2090.310791,1727.293823 2144.660400,1687.950317 
	C2189.278320,1655.651611 2230.324463,1619.303955 2268.014893,1579.264526 
	C2309.383545,1535.317993 2345.394531,1487.249390 2376.179443,1435.239258 
	C2424.672363,1353.312134 2458.269287,1265.678101 2478.454346,1172.736938 
	C2486.552490,1135.449219 2492.702637,1097.893555 2496.377686,1059.913208 
	C2498.198730,1041.094360 2499.236084,1022.231445 2500.569580,1003.384277 
	C2503.017578,968.792664 2502.978271,934.190674 2501.420654,899.668640 
	C2499.150635,849.353271 2493.889404,799.310791 2484.612305,749.677307 
	C2471.744385,680.829712 2451.808838,614.231201 2423.587402,550.169373 
	C2394.211426,483.486938 2356.539062,421.799591 2310.636719,365.165161 
	C2288.610596,337.988953 2264.903809,312.342682 2239.598877,288.230774 
	C2195.889160,246.581818 2148.154785,210.070236 2096.895996,178.178970 
	C2020.578247,130.696960 1939.055542,94.664978 1853.407959,67.756470 
	C1803.024170,51.927059 1751.854370,39.275860 1699.905884,29.711853 
	C1657.529053,21.910017 1614.962769,15.636294 1572.050415,11.549459 
	C1545.642578,9.034465 1519.247070,6.761527 1492.744629,5.413927 
	C1483.810913,4.959661 1474.856323,4.660497 1465.027832,3.956997 
	C1463.761963,3.467006 1463.385376,3.207677 1463.504395,2.474174 
	C1845.919434,2.000000 2227.838867,2.000000 2610.000000,2.000000 
	C2610.000000,1000.041809 2610.000000,1998.083618 2610.000000,2997.062744 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M716.937317,3002.000000 
	C716.111328,2998.009033 716.319885,2994.018311 716.320068,2990.027344 
	C716.335022,2643.093994 716.325317,2296.160645 716.363159,1949.227173 
	C716.364807,1934.317627 714.367065,1936.369629 728.827393,1936.364380 
	C868.133972,1936.313477 1007.440735,1936.394653 1146.746948,1936.173096 
	C1154.591187,1936.160645 1158.929810,1938.354370 1163.194702,1945.364380 
	C1227.464844,2051.003906 1292.123413,2156.407227 1356.729858,2261.842041 
	C1413.348755,2354.241455 1470.047119,2446.592285 1526.653564,2538.999268 
	C1580.654053,2627.152344 1634.541016,2715.375244 1688.553589,2803.520752 
	C1728.846802,2869.276855 1769.261230,2934.958496 1809.810791,3001.336670 
	C1445.958252,3002.000000 1081.916382,3002.000000 716.937317,3002.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M1463.064087,2.000000 
	C1464.000000,2.000000 1464.136475,2.857776 1464.137573,3.292055 
	C1452.420166,4.030044 1440.699707,4.553226 1428.497314,2.700003 
	C1439.376099,2.000000 1450.752075,2.000000 1463.064087,2.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M1563.976929,275.684326 
	C1616.442017,286.164612 1650.039185,316.414459 1662.516235,367.697052 
	C1678.352051,432.784088 1633.159912,499.657715 1565.597534,510.294067 
	C1526.077515,516.515747 1492.226685,504.405182 1463.193726,477.625671 
	C1459.796387,474.491974 1456.565674,470.965973 1454.102905,467.085388 
	C1451.164429,462.455475 1448.565186,463.697205 1445.332031,466.304962 
	C1434.987427,474.648621 1424.611816,482.954254 1414.226562,491.247437 
	C1315.052734,570.443054 1215.865723,649.622253 1116.742310,728.880859 
	C1113.953857,731.110474 1110.486450,732.828674 1108.861694,736.716736 
	C1114.667236,745.406860 1121.541016,753.441467 1126.675659,762.753601 
	C1137.910889,783.130249 1144.546631,804.652283 1146.474365,827.926453 
	C1149.136841,860.071350 1142.067017,889.765503 1126.357788,917.656860 
	C1122.653687,924.233582 1122.644287,924.249329 1130.079956,930.138611 
	C1182.695068,971.810791 1235.305908,1013.488281 1287.937500,1055.139526 
	C1316.075317,1077.407104 1344.246948,1099.632446 1372.415039,1121.861938 
	C1378.768555,1126.875977 1378.817017,1126.869385 1383.877808,1120.931030 
	C1422.360352,1075.775391 1470.043945,1044.900635 1527.878296,1031.411743 
	C1616.178833,1010.817078 1696.310547,1029.656616 1766.008789,1087.246216 
	C1819.308716,1131.286377 1850.920898,1188.667480 1860.329102,1257.581055 
	C1871.447632,1339.024658 1849.053345,1410.973511 1796.081909,1472.977661 
	C1755.667480,1520.283569 1703.704468,1549.578857 1642.650635,1561.329590 
	C1585.984131,1572.235718 1531.088135,1565.194336 1478.445435,1541.042236 
	C1449.087158,1527.572876 1422.885986,1509.552612 1400.303467,1486.651611 
	C1358.216797,1443.971191 1332.194336,1393.079224 1323.583130,1333.427246 
	C1314.454102,1270.190063 1326.861084,1211.401245 1358.865601,1156.441040 
	C1359.367065,1155.580200 1359.685791,1154.578125 1360.315918,1153.833374 
	C1363.305908,1150.299438 1362.734985,1148.113770 1358.850952,1145.131470 
	C1340.406372,1130.969238 1322.296509,1116.371216 1304.060425,1101.937256 
	C1238.408447,1049.972778 1172.753662,998.011841 1106.820557,945.828491 
	C1091.809204,961.033875 1075.030518,973.824890 1055.553223,983.025146 
	C997.021362,1010.673157 926.444946,997.973999 881.803833,954.295349 
	C876.339844,948.949097 870.373474,943.969666 866.207458,937.503967 
	C863.132996,932.732544 860.733276,933.320312 856.777100,936.542969 
	C839.776428,950.391602 822.507385,963.910217 805.377197,977.600647 
	C761.515137,1012.655334 717.673584,1047.735718 673.832275,1082.816406 
	C672.276428,1084.061401 670.810364,1085.418701 669.142883,1086.861694 
	C669.337463,1090.723633 672.488770,1093.002686 674.526978,1095.816772 
	C698.830933,1129.374878 713.216187,1166.733521 717.907654,1207.883301 
	C722.965698,1252.247925 715.537109,1294.615479 696.232727,1334.638672 
	C673.730774,1381.291260 639.206848,1416.698364 593.391296,1441.132812 
	C565.116821,1456.212036 534.926575,1465.216187 503.218933,1467.419067 
	C438.661163,1471.904175 381.078094,1453.267090 331.744049,1411.084961 
	C288.092712,1373.761353 261.765686,1326.111328 252.714447,1269.650513 
	C245.064056,1221.928223 251.997131,1175.816772 273.212799,1132.009888 
	C296.195190,1084.554932 331.225983,1048.764282 377.730560,1024.504395 
	C405.941376,1009.787842 436.185181,1001.820679 468.203888,999.429382 
	C511.846527,996.169861 552.622009,1005.207153 591.385681,1024.511719 
	C610.213806,1033.888306 627.413574,1045.905029 642.769165,1060.333984 
	C650.665161,1067.753296 649.337891,1068.225098 658.007629,1061.322021 
	C691.796509,1034.418091 725.417480,1007.303589 759.162048,980.343811 
	C786.414734,958.570557 813.846863,937.020813 840.997742,915.121948 
	C848.628052,908.967590 848.520996,910.937805 844.821411,901.620483 
	C821.464172,842.796082 829.385437,787.872620 869.213562,738.984924 
	C895.620239,706.571716 930.769165,688.122375 972.912842,683.582581 
	C1013.094788,679.254028 1049.105225,689.691406 1081.925049,712.572937 
	C1088.614502,717.236694 1088.607544,717.258667 1095.197876,711.988464 
	C1190.683228,635.629395 1286.163696,559.264160 1381.655029,482.912506 
	C1398.263550,469.632904 1414.819214,456.283783 1431.618774,443.249664 
	C1435.686401,440.093811 1436.444580,437.626862 1434.750244,432.452606 
	C1421.392578,391.663025 1427.322876,353.667938 1453.787842,319.742279 
	C1472.959839,295.165527 1498.465454,280.491943 1529.463379,275.666473 
	C1540.698242,273.917511 1551.885864,273.628998 1563.976929,275.684326 
z"/>
</svg>
</g>

      </svg>`;
    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 24,
      height: 28,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 24,
      height: 34,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 24,
      height: 34,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
